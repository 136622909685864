.imt-card-img {
    margin: 0 10px 0 0;
    width: auto;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1;
}

.imt-col {
    margin: 10px 10px 10px 10px;
    max-width: 460px;
}

.imt-header {
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 38px;
    min-width: 300px;
    margin-top: 20px;
}

.imt-card {
    white-space: nowrap;
    background: white;
    position: relative;
    text-overflow: ellipsis;
    padding: 0 5px 0 0;
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 85px;
    width: 100%;
    margin: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #8a8a8a;
    min-width: 375px;
    max-width: 375px;
}

.imt-card-offset {
    left: 20px;
}

.imt-divider-center {
    position: absolute;
    width: 3px;
    height: 70px;
    left: 50%;
    background-color: #EAECF0;
}

.imt-divider {
    position: absolute;
    width: 3px;
    height: 120px;
    left: 50%;
    background-color: #EAECF0;
}

.imt-divider-sm {
    position: absolute;
    width: 3px;
    height: 50px;
    left: 50%;
    background-color: #EAECF0;
}

.imt-collapse-btn {
    border-radius: 20px;
    padding: 2px 20px 2px 20px;
    left: 35%;
    position: relative;
    background: white;
    color:rgb(162, 162, 162);
    z-index: 98;
}

.imt-add-button {
    padding: 5px 15px 5px 5px;
    left: 42%;
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.imt-add-button-offset {
    left: 42%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.imt-hamburger-button {
    color: rgb(162, 162, 162);
}

.imt-hamburger-menu {
    position: absolute;
    left: 92%;
    top: 10%;
}

.imt-locked-icon {
    position: absolute;
    left: 88%;
    top: 14%;
}

.imt-menu-list {
    margin: 0;
    padding: 0;
}

.placeholder-imt-card {
    white-space: nowrap;
    background:rgb(255, 221, 221);
    position: relative;
    text-overflow: ellipsis;
    padding: 0 5px 0 0;
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 85px;
    width: 100%;
    margin: 25px 25px 5px 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #8a8a8a;
    min-width: 375px;
    max-width: 375px;
}

.imt-warning-text {
    color: rgb(245, 46, 46);
    background: white;
    position: relative;
    z-index: 100;
    left: 8%;
    margin-bottom: 10px;
}

.imt-spinner {
    display: inline-block;
    margin: 10px auto 10px auto;
}

#tooltip-top > .tooltip-inner {
    background-color: #fff;
    color: #8a8a8a;
    border: 1px solid lightgray;
}

.tooltip > .arrow::before {
    border-top-color: lightgray;
}

/* Profile view styling imported from RallyPoint OSER Styles */

.profile-view-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
    height: 100%;
    width: 100vw;
    z-index: 999;
}

.profile-view {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
    background-color: white;
    padding: 10px 20px;
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
    border-left: 2px solid lightgray;
    z-index: 1000;
}

.profile-view-close-wrapper {
    display: flex;
    flex-direction: column;
    align-items: right;
    margin-bottom: 10px;
}

.profile-view-box {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 16px 14px;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    border-radius: 5px;
    margin: 0 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.profile-view-info-box {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 14px 16px;
    margin: 0 0 10px 0;
}

.profile-view-emp-img {
    margin: 0 10px 0 0;
    padding: 0;
    width: 97px;
    height: 97px;
    object-fit: cover;
    aspect-ratio: 1;
    border-radius: 4px;
}

.profile-view-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1px;
}

.profile-view-emp-info-font {
    font-size: 14px;
}

.profile-view-name-text {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 600;
}

.profile-view-title-text {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
}

.profile-view-main-info {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 400;
}

.profile-view-info-pipe {
    background-color:rgb(189, 189, 189);
    height: 20px;
    width: 1px;
    margin: 2px 7px 0 0;
    flex: none;
}

.profile-view-main-info p:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
}

.profile-view-main-info p:last-child {
    font-weight: 200;
}

.profile-view-section-title {
    font-size: 16px;
    font-weight: 700;
}

.profile-view-info-row {
    display: flex;
    flex-direction: row;
}

.profile-view-info-row-col {
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 14px;
    font-weight: 400;
}

.profile-view-info-key {
    color: gray;
}